<template>
    <van-tabbar v-model="activeIndex" active-color="#e2ba7f">
        <van-tabbar-item v-for="(item,index) in pages" :key="index" @click="pageTo(index)">
            <span style="font-weight: bold;">{{item.name}}</span>
            <template #icon="props">
                <img :src="active === index?item.urls:item.url"/>
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    export default {
        name: "TalentTabbarDialog",
        props: ['active'],
        data() {
            return {
                activeIndex:0,
                pages: [
                    {
                        path: '/talent',
                        name: '任务大厅',
                        urls: require('../../../assets/img/home/1s.png'),
                        url: require('../../../assets/img/home/1.png'),
                    },{
                        path: '/talent/mine',
                        name: '已接任务',
                        urls: require('../../../assets/img/home/2s.png'),
                        url: require('../../../assets/img/home/2.png'),
                    },{
                    //     path: '/lottery',
                    //     name: '我的订单',
                    //     urls: require('../../../assets/img/home/4s.png'),
                    //     url: require('../../../assets/img/home/4.png'),
                    // },{
                        path: '/talent/list',
                        name: '我的账户',
                        urls: require('../../../assets/img/home/3s.png'),
                        url: require('../../../assets/img/home/3.png'),
                    }
                ],
            }
        },
        mounted() {
            this.activeIndex = this.active;
        },
        methods: {
            pageTo(index) {
                if(index === this.active) return;
                this.$emit('navigate',this.pages[index].path);
            },
        },
    }
</script>

<style scoped>

</style>